@media screen and (max-width:1920px) and (min-width:1500px) {
    .container,
    .container-lg,
    .container-md,
    .container-sm,
    .container-xl {
        max-width: 1440px
    }
    .service-stats {
        padding: 0 6%
    }
}

@media(min-width:1200px) {
    .w-992 {
        max-width: 992px
    }
}

@media screen and (max-width:1499px) and (min-width:1300px) {
    .container,
    .container-lg,
    .container-md,
    .container-sm,
    .container-xl {
        max-width: 1240px
    }
    .fill-fixed {
        height: 250px
    }
    .w-992 {
        max-width: 992px
    }
    .portfolio-detls {
        margin-top: 0
    }
    .ree-card-content h3 {
        font-size: 24px;
        line-height: 34px
    }
    h1.h1 {
        font-size: 50px;
        line-height: 62px
    }
    h2 {
        font-size: 36px;
        line-height: 48px
    }
    .stats-box h3 {
        font-size: 45px
    }
    .fwb-main {
        padding: 50px 60px
    }
    .home-app {
        height: auto;
        padding: 120px 0
    }
    .home-creative-agency {
        height: 700px
    }
    .lead-gen {
        margin-top: 85px;
        padding-top: 50px
    }
}

@media screen and (max-width:1299px) and (min-width:1100px) {
    .blur-bg {
        width: 700px;
        height: 500px
    }
    .container,
    .container-lg,
    .container-md,
    .container-sm {
        max-width: 1080px
    }
    h1.h1 {
        font-size: 50px;
        line-height: 62px
    }
    h2 {
        font-size: 35px;
        line-height: 45px
    }
    .stats-box h3 {
        font-size: 40px
    }
    .stats-box {
        padding: 30px 18px;
        min-height: 310px
    }
    .ree-card-content h3 {
        font-size: 20px;
        line-height: 30px
    }
    .half-blog-img {
        height: 390px
    }
    .c-infot {
        width: 50%
    }
    .home-app {
        height: auto;
        padding: 120px 0
    }
}

@media only screen and (max-width:1350px) {
    .ree-nav .nav-list li a.menu-links{ font-size: 14px;}
    .ree-btn { font-size: 14px;}
}

@media only screen and (max-width:1200px) {
    .client-logo-set {
        grid-template-columns: auto auto auto auto auto auto auto auto;
        grid-gap: 20px;
        padding-bottom: 20px;
        overflow-x: auto
    }
    .digi-service:after {
        right: 0
    }
    .btn-sets2 {
        display: block
    }
    .key-highlt-b h3 {
        font-size: 35px;
        line-height: 45px
    }
    .ree-app-st-a {
        width: 30%
    }
    .ree-app-st-b {
        width: 70%
    }
    .home-app {
        height: auto;
        padding: 120px 0
    }
    h1.h1 {
        font-size: 50px;
        line-height: 60px
    }
    .ree-nav {
        display: none
    }
    .ree-nav-cta {
        display: none
    }
    .mobile-menu2 {
        display: block
    }
    .header-dark .hc-nav-trigger span,
    .header-dark .hc-nav-trigger span::after,
    .header-dark .hc-nav-trigger span::before {
        background: #fff
    }
    .header-dark.sticky .hc-nav-trigger span,
    .header-dark.sticky .hc-nav-trigger span::after,
    .header-dark.sticky .hc-nav-trigger span::before {
        background: #34495e
    }
    .header-dark .ree-btn,
    .header-dark .ree-btn2 {
        box-shadow: none
    }

    .hc-offcanvas-nav a.nav-next:before { display: none; }
}

@media screen and (max-width:1099px) and (min-width:992px) {
    .team-img {
        height: 280px
    }
    .form-blocks {
        padding-left: 0;
        margin-top: 60px
    }
    .ree-card {
        padding: 30px 20px
    }
    .statsnum span {
        font-size: 24px
    }
    .col-right-a {
        width: 100%;
        padding-right: 15px;
        padding-left: 15px
    }
    .setps {
        right: 15px;
        top: 20px
    }
    .col-right-b {
        width: 100%;
        padding-right: 15px;
        padding-left: 15px
    }
    .fourc-up-b {
        margin: 60px 0 0
    }
    .port-title-main {
        font-size: 26px;
        line-height: 35px
    }
    .contact-details-block {
        padding: 15px
    }
    .ree-details-set {
        margin: 0 0 0 12px
    }
    .dtb-details a {
        letter-spacing: 0;
        font-size: 19px
    }
    .dtb-details span {
        font-size: 18px
    }

    .Industries-serve ul li{ flex: 0 33%; max-width: 33%; }
}

@media only screen and (max-width:1200px) {
    .creative-banner-img { display: none;}
    .home-creative-agency {
        height: 645px
    }
    .col-5s {
        width: 33.333%
    }
    .icon-card-title {
        padding: 0
    }
    .hero-right-cnt .media {
        padding-left: 0
    }
}

@media only screen and (max-width:992px) {
    .hire-app-list-mobile { display: block !important;}
    .high-light-links { display: none;}
    .portfolio-brand .react-tabs__tab-list {
        border-bottom: 1px solid #dee2e6;
        display: flex;
        overflow: auto;
        white-space: nowrap;
        padding-bottom: 1px;
        width: 100%;
        flex-wrap: inherit;
    }
    .portfolio-brand .tab-17.tabs-layout .react-tabs__tab{
        font-size: 18px;
    }
    .hero-right-cnt .media {
        margin-top: 60px
    }
   
    .home-hero-pp .hero-content-pp {
        text-align: center
    }
    .logo-design .hero-content-a {
        padding-right: 0
    }
    .d-ml50 {
        margin-left: 0;
        margin-top: 60px
    }
    .icon-block-ree {
        grid-template-columns: auto auto auto
    }
    .owlbg11 {
        background-position: right top;
        background-size: cover
    }
    .slide-hero h1 {
        font-size: 30px;
        line-height: 40px
    }
    .hero-content-x h1 span {
        font-size: 30px;
        line-height: 40px
    }
    .cinftt+.cinftt {
        margin-top: 50px
    }
    .slide-hero {
        margin-top: 0px;
        min-height: 530px
    }
    .sevctxt h3 {
        font-size: 20px;
        line-height: 28px
    }
    .form-blocks {
        padding-left: 0;
        margin-top: 60px
    }
    .img-full-bgimg img {
        height: auto;
        width: 100%;
        object-fit: cover
    }
    .full-card-link {
        width: 100%
    }
    .img-full-bgimg img {
        height: auto
    }
    .full-card-footer {
        padding: 20px
    }
    .itemcont {
        margin-left: 20px;
        width: 60%
    }
    .itmmlogo {
        width: 40%
    }
    .itmmlogo img {
        width: 90%
    }
    .ree-app-st-a {
        width: 100%
    }
    .ree-app-st-b {
        width: 100%
    }
    .btn-sets2 {
        display: flex;
        justify-content: center
    }
    .btnctm {
        text-align: center
    }
    .key-and-award {
        margin-top: -40px
    }
    .app-awards {
        overflow: auto;
        white-space: nowrap;
        padding-bottom: 30px;
        width: 100%
    }
    .ft-copyright {
        text-align: center
    }
    .ft-r {
        text-align: center;
        padding-top: 0
    }
    .col-5s {
        width: 33.333%
    }
    .our-app-d {
        text-align: center
    }
    .price-list-tab li a {
        width: 100%
    }
    .price-list-tab li {
        display: inline-block;
        width: 100%;
        margin: 0 0 5px
    }
    .header--btn {
        display: block;
        justify-content: space-between;
        padding: 0;
        align-items: center;
        text-align: center
    }
    .header--btn a {
        margin-top: 20px
    }
    .price-table-heading {
        display: none
    }
    .price-table-ree .price-ree li.heading {
        width: 100%;
        border-radius: 8px 8px 0 0
    }
    .price-table-ree .price-ree li.content {
        width: 100%
    }
    .price-table-ree .price-ree li.price {
        width: 100%;
        border-radius: 0 0 8px 8px
    }
    .br-br {
        border-radius: 0 0 8px 8px
    }
    .price-ree li {
        padding: 10px 15px;
        display: block;
        align-items: center;
        vertical-align: middle;
        text-align: center !important
    }
    .price-table-ree+.price-table-ree {
        margin-top: 30px
    }
    .m-order1 {
        order: 1
    }
    .m-order2 {
        order: 2
    }
    h1.h1 {
        font-size: 45px;
        line-height: 55px
    }
    h1 {
        font-size: 40px;
        line-height: 50px
    }
    h2 {
        font-size: 32px;
        line-height: 42px
    }
    h3 {
        font-size: 24px;
        line-height: 34px
    }
    h4 {
        font-size: 20px;
        line-height: 30px
    }
    h5 {
        font-size: 18px;
        line-height: 28px
    }
    h6 {
        font-size: 17px;
        line-height: 26px
    }
    p {
        font-size: 18px;
        line-height: 28px
    }
    .sec-pad {
        padding-top: 80px;
        padding-bottom: 80px
    }
    html body .pt90 {
        padding-top: 80px
    }
    html body .pt80 {
        padding-top: 50px
    }
    html body .pb120 {
        padding-bottom: 80px
    }
    html body .m-mt0 {
        margin-top: 0
    }
    .link-serv {
        opacity: 1
    }
    .fwb-main {
        padding: 50px 15px
    }
    .colra {
        height: 950px
    }
    .fill-fixed {
        height: 210px
    }
    .ree-subs-from {
        margin-left: 0;
        margin-top: 40px
    }
    .port-title-main {
        font-size: 26px;
        line-height: 35px
    }
    .ref-logo {
        margin-top: 30px
    }
    .pdl-dtls {
        margin-right: 0;
        padding-left: 0
    }
    .pdr-dtls {
        margin-left: 0;
        padding-right: 0
    }
    .setps {
        opacity: 1;
        font-size: 54px
    }
    html .m-ml0 {
        margin-left: 0
    }
    html .m-mr0 {
        margin-right: 0
    }
    .tab-bg {
        padding: 40px 0 0
    }
    .hero-content-a {
        text-align: center
    }
    .btn-sets {
        justify-content: center
    }
    .side-pghd {
        margin-bottom: 30px
    }
    .blur-bg {
        width: 300px;
        height: 300px
    }
    .home-hero-a {
        padding-top: 150px;
        padding-bottom: 100px;
        height: auto
    }
    .review-slider {
        width: 95%
    }
    .work-details {
        text-align: center
    }
    .work-thumbnails {
        text-align: center;
        align-items: flex-end
    }
    .col-right-a {
        width: 100%;
        padding-right: 15px;
        padding-left: 15px
    }
    .col-right-b {
        width: 100%;
        padding-right: 15px;
        padding-left: 15px
    }
    .fourc-up-b {
        margin: 60px 0 0
    }
    .footer-rowset {
        display: grid;
        justify-content: space-between;
        grid-template-columns: auto auto auto
    }
    .footer-ree-lg {
        text-align: center
    }
    .ft-btn {
        text-align: center;
        margin-top: 30px
    }
    .ct-sol-img,
    .sol-img-png {
        margin-top: 40px
    }
    html .m-mt30 {
        margin-top: 30px
    }
    html .m-mb30 {
        margin-bottom: 30px
    }
    html .m-mb60 {
        margin-bottom: 60px
    }
    html .m-mt60 {
        margin-top: 60px
    }
    .info-bar {
        margin: 20px 0
    }
    .ree-card-content h3 {
        font-size: 24px;
        line-height: 32px
    }
    .vrt-tabb {
        display: flex;
        overflow: auto;
        white-space: nowrap;
        padding-bottom: 30px;
        width: 100%;
        flex-wrap: inherit
    }
    .vrt-tabb li {
        width: fit-content;
        vertical-align: middle;
        display: inline-block
    }
    .vrt-tabb li+li {
        margin-left: 10px
    }
    .Industries-serve ul{ flex-wrap: wrap;  }
    .Industries-serve ul li{ padding: 15px; flex: 0 33%; max-width: 33%; justify-content: center; text-align: center;}
    .Industries-serve ul li h6{font-size: 16px;}
    .page-heading-sec{padding-top: 0 !important;}
}

@media only screen and (max-width:767px) {
    
    .ree-btn {
        font-size: 16px;
        padding: 0 26px;
        line-height: 40px;}
    .lead-number h2 {
        font-size: 30px;
        line-height: 40px;
        margin-bottom: 20px
    }
    .lead-gen {
        text-align: center;
        padding-top: 50px;
        padding-bottom: 60px
    }
    .icon-block-ree p {
        font-size: 16px;
        line-height: 24px
    }
    .mini-header-bar {
        display: none
    }
    html body .header-bhv {
        height: 85px
    }
    .slide-hero {
        margin-top: 0px;
        min-height: auto
    }
    .slide-hero h1 {
        font-size: 35px;
        line-height: 45px
    }
    .hero-content-x h1 span {
        font-size: 35px;
        line-height: 45px
    }
    .owlbg11 {
        background-size: auto
    }
    .home-contact-blocks .c-infot {
        width: 100%
    }
    .home-creative-agency {
        height: 700px
    }
    html .ml50 {
        margin-left: 0
    }
    .number-f h2 {
        font-size: 25px;
        line-height: 40px;
        width: 40px;
        height: 40px
    }
    .numbers-feature {
        grid-template-columns: 40px auto;
        grid-gap: 15px
    }
    html .ml25 {
        margin-left: 0
    }
    .item-filter {
        display: none
    }
    .key-highlt-b h3 {
        font-size: 30px;
        line-height: 40px;
        margin: 0 0 5px
    }
    .col-5s {
        width: 50%
    }
    .key-highlt-b h3 {
        font-size: 25px;
        line-height: 35px;
        margin: 0 0 5px
    }
    .key-highlt-b p {
        font-size: 16px;
        line-height: 24px
    }
    .quick-key-points {
        padding: 30px
    }
    .dark-light-a {
        background: #131419
    }
    .location-home .col-lg-4:first-child .mt60 {
        margin-top: 0
    }
    .home-partners-block .brand-logo img {
        max-width: 100%
    }
    .clients-logos ul li p {
        font-size: 14px
    }
    .breadcrus,
    .port-links,
    .port-tags,
    .ree-card-link,
    .sub-heading {
        font-size: 17px
    }
    .hero-content-a p {
        font-size: 19px;
        line-height: 29px
    }
    .service-page {
        text-align: left
    }
    .ft-cpy {
        text-align: center;
        padding-top: 15px;
        padding-bottom: 15px
    }
    .ft-r {
        text-align: center;
        padding: 0
    }
    .cta-heading-wide-bt {
        display: block
    }
    .cta-heading-wide-bt h3 {
        margin-right: 0;
        margin-bottom: 30px
    }
    .link-sol-header {
        text-align: center
    }
    .colra {
        height: 1300px
    }
    .trust-logo-block li {
        width: 33%
    }
    .port-ref-link {
        width: 50%
    }
    .industry-workfor {
        padding: 15px;
        display: block;
        text-align: center;
        margin: 0 auto 15px
    }
    .industry-workfor img {
        width: 45px
    }
    .industry-workfor h6 {
        margin: 10px 0 0;
        font-size: 14px;
        line-height: 20px
    }
    .h1a {
        font-size: 35px;
        line-height: 42px
    }
    .company-stats2 {
        text-align: left
    }
    .statsnum {
        width: 50%
    }
    .form-head {
        text-align: center;
        padding: 40px 40px 20px
    }
    .form-body {
        padding: 20px 20px 40px
    }
    .footer-links-list li a {
        font-size: 15px;
        line-height: 34px
    }
    .footer-rowset {
        display: grid;
        justify-content: space-between;
        grid-template-columns: auto auto
    }
    .ref-logo {
        display: grid;
        justify-content: space-evenly;
        grid-template-columns: auto auto
    }
    .c-infot {
        width: 100%
    }
    .contact-infos {
        display: block
    }
    .c-infot+.c-infot {
        margin-left: 0;
        margin-top: 30px
    }
    .ree-header {
        padding: 0 15px
    }
    .m-p-l-r-0 {
        padding: 0
    }
    .email-subs-form input {
        padding: 0 85px 0 15px
    }
    .ree-subs span {
        display: none
    }
    .hero-content-a {
        text-align: center
    }
    .ctablock {
        padding: 50px 20px
    }
    .half-blog-content {
        padding: 30px 20px;
        min-height: auto
    }
    .half-blog-card {
        margin-bottom: 30px
    }
    .m-w100 {
        width: 100%;
        text-align: center
    }
    .stats-box p {
        font-size: 16px
    }
    .stats-box {
        border-radius: 8px;
        padding: 30px 20px;
        min-height: auto
    }
    .stats-box h3 {
        font-size: 35px
    }
    .company-stats {
        padding: 0
    }
    .tags a {
        font-size: 15px;
        margin: 20px 0 15px
    }
    .rpl-contt {
        padding: 0 15px 15px
    }
    .heading-hz-btn {
        text-align: center
    }
    .cta-heading {
        text-align: center
    }
    .process-content {
        min-height: fit-content
    }
    .portfolio-items .row+.row {
        margin-top: 80px
    }
    .Industries-serve ul{ flex-wrap: wrap;  }
    .Industries-serve ul li{ flex: 0 50%; max-width: 50%; }
    .agency-info-ree {margin: 0px;}
}

@media only screen and (max-width:575px) {
    .w80 {
        width: 60px
    }
    .cta-info-tt {
        padding: 50px 20px
    }
    .dot-c1 {
        width: 60px;
        height: 60px
    }
    
    .our-team .ree-media-crd {
        width: 100%;
        margin: 40px auto 0
    }
    .team-img {
        height: 350px
    }
    .col-5s {
        width: 100%
    }
    .location-block- {
        padding: 0
    }
    .btn-sets2 {
        display: grid;
        justify-content: center
    }
    .quick-key-points {
        grid-gap: 30px;
        grid-template-columns: auto
    }
    .animated-logo .circle-anim {
        width: 100px;
        height: 100px
    }
    .key-and-award {
        margin-top: 20px
    }
    .quick-key-points {
        padding: 20px;
        margin: 0 10px
    }
    .animated-logo {
        width: 100px;
        height: 100px
    }
    .animated-logo .logo-anim {
        width: 70px
    }
    .uniq-points {
        grid-template-columns: auto
    }
    .demo-page h5 {
        font-size: 16px;
        font-weight: 400
    }
    .blog-quick-inf {
        font-weight: 300;
        font-size: 16px
    }
    .ul-list-icon li {
        font-size: 17px;
        line-height: 27px
    }
    .trust-review.owl-carousel .owl-dots {
        bottom: -13px;
        right: 0;
        left: 0;
        text-align: center;
        margin: 0 auto
    }
    .ree-card {
        padding: 30px 20px
    }
    .ree-btn {
        font-size: 15px;
        padding: 0 26px;
        line-height: 40px;}
    .m-center {
        text-align: center
    }
    .dskt-logo .nav-brand img {
        max-height: fit-content;
        max-width: 230px
    }
    html body .pb120 {
        padding-bottom: 60px
    }
    html body .pt90 {
        padding-top: 60px
    }
    .sec-pad {
        padding-top: 60px;
        padding-bottom: 60px
    }
    .subs-btn span {
        display: none
    }
    .or {
        display: none
    }
    .mult-btns a {
        width: max-content;
        text-align: center;
        display: inline-table
    }
    .mult-btns a+a {
        margin-top: 30px
    }
    .ree-why-sec p {
        padding: 0;
        font-size: 16px
    }
    .tab-17.tabs-layout .nav-tabs .nav-link {
        padding: 15px 10px;
        font-size: 16px
    }
    .tab-17.tabs-layout .nav-tabs {
        border-bottom: 1px solid #dee2e6;
        display: flex;
        overflow: auto;
        white-space: nowrap;
        padding-bottom: 1px;
        width: 100%;
        flex-wrap: inherit
    }
    .statsnum p {
        font-size: 15px
    }
    .port-ref-link a span {
        font-size: 17px;
        line-height: 26px
    }
    .icon-txt p {
        font-size: 12px;
        font-weight: 600
    }
    h1.h1 {
        font-size: 38px;
        line-height: 48px
    }
    h1 {
        font-size: 38px;
        line-height: 48px
    }
    h2 {
        font-size: 28px;
        line-height: 38px
    }
    h3 {
        font-size: 22px;
        line-height: 32px
    }
    h4 {
        font-size: 19px;
        line-height: 28px
    }
    h5 {
        font-size: 18px;
        line-height: 28px
    }
    h6 {
        font-size: 17px;
        line-height: 26px
    }
    p {
        font-size: 16px;
        line-height: 26px
    }
    ul.pagination {
        width: 100%;
        margin: 0 auto;
        align-items: center;
        justify-content: space-around
    }
    ul.pagination li:first-child {
        margin-right: 14px;
        font-weight: 700;
        font-size: 14px
    }
    ul.pagination li:last-child {
        margin-left: 14px;
        font-weight: 700;
        font-size: 14px
    }
    ul.pagination li.pageNumber {
        width: 38px;
        height: 38px;
        line-height: 38px;
        text-align: center
    }
    .trust-logo-block li {
        width: 50%
    }
    .ul-list li {
        font-size: 17px;
        line-height: 40px
    }
    .fill-fixed {
        height: auto
    }
    .vrt-tabb .nav-link {
        font-size: 18px;
        padding: 15px
    }
    .info-b-left a,
    .info-b-right {
        font-size: 16px
    }
    .servic-block-a {
        width: 100%
    }
    .vrt-tabb .nav-link i {
        margin-right: 8px
    }
    .heading-hz-btn h2 {
        margin-bottom: 20px
    }
    p {
        font-size: 16px;
        line-height: 26px
    }
    .m-pr7 {
        padding-right: 7px
    }
    .m-pl7 {
        padding-left: 7px
    }
    .big-txt {
        display: grid;
        grid-gap: 10px
    }
    .Industries-serve ul{ flex-wrap: wrap;  }
    .Industries-serve ul li{ padding: 15px; flex: 0 50%; max-width: 50%; justify-content: center; text-align: center;}
    .creative-banner .hire-app-list ul li { padding: 1rem;}
    .creative-banner .hire-app-list ul li i { margin-bottom: 1rem;}

}

@media only screen and (max-width:420px) {
    .creative-banner .hire-app-list ul {flex-wrap: wrap;}
    .creative-banner .hire-app-list ul li i {margin-bottom: .5rem; font-size: 2rem;}
    .ref-links a {
        line-height: 30px
    }
    .icon-block-ree img {
        width: 60px
    }
    .ree-btn {
        font-size: 15px;
        padding: 0 26px;
        line-height: 40px;
    }
    .icon-block-ree {
        grid-template-columns: auto auto;
        grid-gap: 15px
    }
    .team-img {
        height: 300px
    }
    .ree-media-crd:hover .team-img {
        background-size: 300px
    }
    .intro h4 {
        font-size: 15px;
        line-height: 24px
    }
    .intro .col-6 {
        padding-right: 10px;
        padding-left: 10px
    }


}

@media only screen and (max-width:370px) {
    .footer-rowset {display: grid; justify-content: space-between; grid-template-columns: auto}
    .key-highlights {grid-gap: 15px}
    .ree-btn { font-size: 15px; padding: 0 18px; line-height: 40px; }


}

@media only screen and (max-width:300px) {
    .our-team .ree-media-crd { width: 100%; margin: 40px auto 0;}
    
}

@media (max-width: 1600px){
    .UI-UX-slider .slider-ux-img {
        top: 60px;
        right: -13rem;
        width: 75%;
    }
}

@media (max-width: 1366px){
    .UI-UX-slider .slider-ux-img {
        top: 75px;
        right: -11rem;
        width: 75%;
    }
}

@media (max-width: 1280px){
    .container{ max-width: inherit; width: 98%;}
    .UI-UX-slider .slider-ux-img {
        top: 100px;
        right: -12rem;
        width: 75%;
    }
}

@media (max-width: 991px){
    html .pb80, html .pb60{ padding-bottom: 30px; }
    html .pt80, html .pt60{ padding-top: 30px !important; }
    html .mb80, html .mb60{ margin-bottom: 30px; }
    html .mt80, html .mt60, html .mt100{ margin-top: 30px; }
    .sec-pad { padding-top: 30px; padding-bottom: 30px;}
    .portfolio-detls{ margin-top: 0px; }
    .portfolio-flimg { text-align: center; margin-bottom: 30px; margin-top: 30px; }
    .slide-hero .row{ padding-top: 20px; padding-bottom: 20px; }
    .hero-content { margin-top: 30px; margin-bottom: 30px; }
    .JS-Stack-Development-img { margin-bottom: 30px; }
    .head-dez3 { padding-top: 40px; }
    .ree-card{ height: auto; margin-bottom: 30px; }    
    .agency-picture{ display:none;}
    .key-and-award { margin-top: 0px;}
    .join-team-bg{ background: #0c0c0c;}
    .hide-for-mobile{ display:block;}
    .software-slider .software-img {display: none;}
    .UI-UX-slider .slider-ux-img { display: none;}
    
}




@media (max-width: 767px){
.half-blog-card { margin-bottom: 0px; }
.myinfo .m-mb30{ margin-bottom: 0px; }
.portfolio-items .row+.row {margin-top: 40px;}
.sol-img { margin-top: 30px; }
.ree-card-link {margin-bottom: 30px;}
h2.please-fill-form {font-size: 30px; line-height: 36px; }
.icon-side { background-image: url(../images/TI-icon.png); background-repeat: no-repeat;background-position: center center; background-size: contain;}
.contact-addressii br{ display: none;}
.location-content p br{ display: none; }
.join-team-bg { position: relative; }
.join-team-bg .cta-heading { z-index: 99; position: relative; }
.owlbg11 { height: auto; }
.go-button { text-align: left;}
.career-img-destop { display: none; }
.career-img-mobile { display: block; }

.search-filter>ul { flex-wrap: wrap; }
.search-filter>ul>li{ flex: 0 100%; }
.search-filter .Function-filter { border:0px; border-bottom: 1px solid #a9a9a9; padding: 20px 10px; margin: 0px; }
}


@media (max-width: 575px){
.about-main-heading h1 { font-size: 26px; line-height: 1.3; }
.mobile-app-dev h1 { font-size: 26px; line-height: 1.3; }
.mobile-app-dev .pt80 { padding-top: 0px; }
.Android-App-Dev .pt40 { padding-top: 0px; }
.iPhone-range h2 { font-size: 22px; line-height: 32px;}
.iPhone-range .ree-card-link {margin-bottom: 20px; margin-top: 20px;}
.benefit-hybrid-mob h2{ font-size: 24px; line-height: 1.3;}
.page-headings h1 {font-size: 28px; line-height: 38px;}
.page-headings p {font-size: 15px; line-height: 26px;}
.about-content-home h2 { font-size: 20px; line-height: 30px; }
.about-content-home h3 { font-size: 20px; line-height: 32px; }
.about-content-home p { font-size: 16px; line-height: 26px; }
.icon-with-title { border-radius: 10px; margin-top: 20px; padding: 10px 0px ; }
.tabs-layout .tab-content {padding: 10px 0 0;}
.iwt-icon {padding: 0 0 10px;}
.iwt-icon img {width: 40px;}
.key-highlt-b h3 { text-align: center; }
.key-highlt-b p { text-align: center; }
.pt100 { padding-top: 0px; }
.hero-content-x p { font-size: 15px; line-height: 26px; }
.hero-content-x h1 { margin-top: 0px; }
.app-awards img { width: 120px; }
.app-awards p { font-size: 15px; line-height: 22px;}
.client-testimonial-content { font-size: 15px; line-height: 24px; }
.review-text p {font-size: 15px; line-height: 26px;}
.pera-block h2 { font-size: 22px; line-height: 32px; }
.img-round80 { width: 60px; height: 60px; }
.ree-card.trust-review { margin-top: 20px;}
.quote-text h2 { margin-bottom: 10px; }
.user-info h5 { font-size: 16px; }
.user-info p { font-size: 15px; }
.hero-content-x h1 {font-size: 28px; line-height: 34px; margin-bottom: 10px; }
.hero-content-x h1 span {font-size: 28px; line-height: 34px; margin-bottom: 10px; }
.hero-content-x p { line-height: 24px; }
.hero-content-x a { margin-top: 20px; }
.ul-list-icon li + li { margin-top: 6px;}
.ul-list-icon li {font-size: 16px;}
.Industries-serve ul li figure { margin: 0px 0 0.5rem; }
.Industries-serve ul li figure img { width: 50px; }
.Industries-serve ul li figcaption h6 { font-size: 15px; font-weight: normal;}
.Industries-serve ul{ flex-wrap: wrap;  }
.Industries-serve ul li{ flex: 0 50%; max-width: 50%; }
.Industries-serve ul h6 { font-size: 14px; line-height: 20px;}
.Industries-serve ul li a { height: 100%; padding: 10px;}
.location-block- .location-content{ width: 100%; float: left; }
.half-bg-ree .mt30 {margin-top: 20px;}
.ree-card { height: auto; margin-bottom: 15px;}
.mt30 { margin-top: 10px !important;}
.mb15 { margin-bottom: 10px !important;}
.sec-pad {padding-top: 10px; padding-bottom: 10px;}
.ree-card-content h3 { font-size: 20px; line-height: 30px; }
.career-text-below-img h4 { font-size: 18px;  font-weight: 300; }
.job-apply-form .ree-card { padding: 30px 20px; }
.career-detail-head .go-button a { padding: 8px 20px;}

}

@media (max-width: 480px){
.slider-content .hire-app-list{display: grid; grid-template-columns: repeat(1, 1fr);}
}


@media (max-width: 400px){
.footer-rowset { grid-template-columns: auto; }
.about-TGT .about-targeticon-card-img img { width: 55px;}
.about-TGT .side-texts h5 {font-size: 16px; line-height: 26px;}
.page-headings h1 {font-size: 24px; line-height: 36px;}
.about-content-home h2 { font-size: 20px; line-height: 30px; }
p {font-size: 15px; line-height: 25px;}
.ree-card-content p {font-size: 15px; line-height: 25px;}
.cta-heading p { font-size: 15px; line-height: 25px; }
.iwt-content p { font-size: 15px; line-height: 25px;}
.clients-logos ul li p {font-size: 15px;}
.statsnum p {font-size: 15px;}
.process-content p { font-size: 15px;}
.uniq-card p {font-size: 15px; line-height: 25px;}
.career-detail p {font-size: 15px; list-style: 25px;}
.hero-content-a > p {font-size: 16px; line-height: 27px;}
h1 { font-size: 29px; line-height: 40px; }
h2 { font-size: 22px; line-height: 32px; }
}



@media (max-width: 375px) {
.dskt-logo .nav-brand img {max-height: fit-content; max-width: 190px;}
.mob-nav2 .ree-btn2 {border-radius: 8px; display: inline-block; line-height: 35px; width: 35px; height: 35px;}
.mob-nav2 {align-items: center;}
.contact-addressii br{ display: none;}
.key-highlt-b h3 {font-size: 22px; line-height: 45px}
.key-highlt-b p {font-size: 15px; line-height: 24px}
}

@media screen and ( min-width: 1151px) and (max-width: 1366px){
.dskt-logo .nav-brand img { width: auto;height: 45px; }
.ree-nav .nav-list li a.menu-links { font-size: 14px; }
.ree-nav-cta a.ree-btn.ree-btn0.ree-btn-grdt2 {
    line-height: 36px !important;
    padding: 0 20px;
}
}

@media (max-width: 349px){
.portfolio-see-more-btn a { font-size: 14px; padding: 10px 15px; }
}

